<template>
  <div class="input-group color-picker" ref="colorpicker">
    <span class="input-group-addon color-picker-container">
      <span class="current-color" :style="'background-color: ' + colorValue" @click="togglePicker()"></span>
      <chrome-picker :value="colors" @input="updateFromPicker" v-if="displayPicker" />
    </span>
    <a-input v-if="withClipboard" class="form-control pk_input with-clipboard" v-model="colorValue" @focus="showPicker()" @input="updateFromInput" />
    <a-input v-else class="form-control pk_input" v-model="colorValue" @focus="showPicker()" @input="updateFromInput" />
    <span class="color-picker-clipboard-container" v-if="withClipboard">
       <span class="color-picker-clipboard">
         <a-tooltip title="Copy to clipboard" placement="bottom">
            <a href="javascript:;"><a-icon type="copy" class="font-size-18" @click="clipboardHandle(colorValue)" /></a>
          </a-tooltip>
       </span>
    </span>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  name: 'inputColorPicker',
  components: {
    'chrome-picker': Chrome,
  },
  props: ['color', 'withClipboard'],
  data() {
    return {
      colors: {
        hex: '#000000',
      },
      colorValue: '',
      displayPicker: false,
    }
  },
  mounted() {
    this.setColor(this.color || '#000000')
  },
  methods: {
    setColor(color) {
      this.updateColors(color)
      this.colorValue = color
    },
    updateColors(color) {
      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color,
        }
      } else if (color.slice(0, 4) === 'rgba') {
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
        const hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)
        this.colors = {
          hex: hex,
          a: rgba[3],
        }
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick)
      this.displayPicker = true
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick)
      this.displayPicker = false
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker()
    },
    updateFromInput() {
      this.updateColors(this.colorValue)
    },
    updateFromPicker(color) {
      this.colors = color
      if (color.rgba.a === 1) {
        this.colorValue = color.hex
      } else {
        this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')'
      }
    },
    documentClick(e) {
      const el = this.$refs.colorpicker
      const target = e.target
      if (el !== target && !el.contains(target)) {
        this.hidePicker()
      }
    },
    // clipboardMessage(field, v) {
    //   this.$notification.success({
    //     message: field + ' copied to clipboard',
    //     description: v,
    //   })
    // },
    clipboardHandle(text) {
      this.$clipboard(text)
      // this.clipboardMessage('Promocode', text.slice(0, 6) + '...')
    },
  },
  watch: {
    colorValue(val) {
      if (val) {
        this.updateColors(val)
        this.$emit('input', val)
        // document.body.style.background = val;
      }
    },
  },
}
</script>
<style lang="scss">
.vc-chrome {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 9;
}
.current-color {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #000;
  border-radius: 2px;
  margin-top: calc(50% - 10px);
  margin-left: calc(50% - 10px);
  cursor: pointer;
}
.color-picker-container, .color-picker-clipboard-container {
  display: inline-block !important;
  border: 1px solid #D9D9D9 !important;
  border-right-style: none !important;
  background: transparent;
  border-radius: 2px 0px 0px 2px;
  height: 40px;
  width: 40px;
}
.color-picker-clipboard-container {
  width: 28px;
  border-radius: 0px 2px 2px 0px;
  border-right-style: solid !important;
  border-left-style: none !important;
  .color-picker-clipboard {
    margin: 2px 0 0 2px;
  }
}
.pk_input.ant-input {
  display: inline-block !important;
  width: calc(100% - 40px - 28px) !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.pk_input.ant-input::selection, .pk_input.ant-input:hover, .pk_input.ant-input:focus  {
  border-color: #D9D9D9 !important;
}
.pk_input.with-clipboard.ant-input {
  border-right-style: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
<style scoped>
</style>
